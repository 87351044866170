import React from "react";
import ReactMarkdown from "react-markdown";
import ClientLogo from "./client-logo";
import { getSafe } from "../../utils/common";

export default ({ client, platform, isPremium }) => {
  let clientData = getSafe(() => client[0]);
  let platformSummary = platform.find(
    item => getSafe(() => item.model.apiKey) === "summary"
  );
  let platformCombinations =
    platform.filter(
      item => getSafe(() => item.model.apiKey) === "platform_combination"
    ) || [];
  return (
    <div class="white-section">
      <ClientLogo clientLogo={clientData}></ClientLogo>
      {isPremium && (
        <React.Fragment>
          <div class="hg-spacing" />
          <div class="container">
            {platformSummary && (
              <React.Fragment>
                <h2 class="h2 centered">{platformSummary.title}</h2>
                <div class="sm-spacing" />
                <div class="p center-align">{platformSummary.subTitle}</div>
                <div class="md-spacing" />
              </React.Fragment>
            )}

            {platformCombinations.map(item => (
              <React.Fragment key={item.id}>
                <div class="shadow-box full-w">
                  <div class="p-24">
                    <div class="flex-container space-between">
                      {item.image && (
                        <div class="platform-combination">
                          <img src={item.image.url} alt={item.image.alt} />
                        </div>
                      )}
                      <div class="_2cols-box _80">
                        <h5>{item.title}</h5>
                        <div class="tn-spacing" />
                        <div class="small-copy">
                          <ReactMarkdown
                            source={item.description}
                            escapeHtml={false}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sm-spacing" />
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { Pagination } from "../../../utils/pagination";
import { getSafe } from "../../../utils/common";
import SortIcon from "./sortIcon";

const ITEM_PER_PAGE = 16;
export default class Sheet extends Component {
  componentWillReceiveProps() {
    let { sheet } = this.props;
    let transformData = new GoogleSheetRows(sheet);
    let pagination = new Pagination(transformData.data, ITEM_PER_PAGE);
    this.setState({
      pagination,
      sheets: transformData,
      data: pagination.pageChanged(1, false),
      forceSelected: 0
    });
  }

  renderRow(row) {
    return (
      <tr>
        <td data-label="Name">
          <span class="data-name">{row.name}</span>
          <br />
          <span class="data-term">{row.term}</span>
        </td>
        <td data-label="Type">
          <span className="text-capitalize data-type">{row.type}</span>
          <br />
          <span class="data-term">{row.outputFormat}</span>
        </td>
        <td data-label="Description">{row.description}</td>
      </tr>
    );
  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      forceSelected: page,
      data: this.state.pagination.pageChanged(page, false)
    });
  };

  sortByType = e => {
    e.preventDefault();
    this.state.sheets.onSortByTypeClick();
    this.reRender();
  };

  isSortByName = () => {
    return this.state && this.state.isSortByNameASC;
  }

  isSortByType = () => {
    return this.state && this.state.isSortByTypeASC;
  }

  sortByName = e => {
    e.preventDefault();
    this.state.sheets.onSortByNameClick();
    this.reRender();
  };

  reRender() {
    let pagination = new Pagination(this.state.sheets.data, ITEM_PER_PAGE);
    let page = this.state.forceSelected || 1;
    this.setState({
      isSortByNameASC: this.state.sheets.isSortByNameASC,
      isSortByTypeASC: this.state.sheets.isSortByTypeASC,
      pagination,
      data: pagination.pageChanged(page, false)
    });
  }

  render() {
    return (
      <React.Fragment>
        <table>
          <thead>
            <tr>
              <th scope="col" className="metric-name">
                Name
                <SortIcon onClick={this.sortByName} isASC={this.isSortByName()}></SortIcon>
              </th>
              <th scope="col" className="metric-type">
                <span>Type</span>
                <SortIcon onClick={this.sortByType} isASC={this.isSortByType()}></SortIcon>
              </th>
              <th scope="col" className="metric-description">
                Description
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state && this.state.data.map(row => this.renderRow(row))}
          </tbody>
        </table>

        {this.state && this.state.pagination.isShowPagination && (
          <React.Fragment>
            <div class="md-spacing"></div>
            <ReactPaginate
              previousLabel={""}
              nextLabel={""}
              pageClassName={"page-number"}
              breakClassName={"page-number"}
              breakLabel={"..."}
              pageCount={this.state.pagination.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

class GoogleSheetRows {
  constructor(sheet) {
    this.isSortByTypeASC = false;
    this.isSortByNameASC = false;

    let rowData = [];
    let sheetData = sheet.data[0] && sheet.data[0];
    if (sheetData) {
      let rowMetadata = sheetData.rowMetadata || [];
      rowData = this.filterHiddenRow(sheetData.rowData, rowMetadata);
    }

    let rawData = (rowData || []).slice(2);
    this.data = rawData
      .map(({ values }, idx) => new GoogleSheetRow(values, idx + 1))
      .filter(this.hasCellValue);
  }

  filterHiddenRow(rowData, metaData) {
    rowData = rowData || [];
    return rowData.filter((row, idx) => {
      let rowMetadata = metaData[idx];
      return rowMetadata && !rowMetadata.hiddenByUser;
    });
  }

  hasCellValue(cell) {
    cell = cell || {};
    let hasKey = Object.keys(cell).length;
    let allHasValues = Object.values(cell).every(Boolean);
    return !!hasKey && !!allHasValues;
  }

  sortByOrder() {
    this.isSortByNameASC = undefined;
    this.isSortByTypeASC = undefined;
    this.data.sort((a, b) => getSafe(() => a.order - b.order));
  }

  sortByType(isASC) {
    this.data.sort((a, b) =>
      getSafe(() =>
        isASC ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type)
      )
    );
  }

  sortByName(isASC) {
    this.data.sort((a, b) =>
      getSafe(() =>
        isASC ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      )
    );
  }

  hasBoolValue(boolVal) {
    return boolVal !== undefined;
  }

  onSortByTypeClick() {
    if (this.hasBoolValue(this.isSortByNameASC)) {
      this.sortByOrder();
    }

    if (!this.hasBoolValue(this.isSortByTypeASC)) {
      this.isSortByTypeASC = true;
    }

    this.sortByType(this.isSortByTypeASC);

    this.isSortByTypeASC = !this.isSortByTypeASC;
  }

  onSortByNameClick() {
    if (this.hasBoolValue(this.isSortByTypeASC)) {
      this.sortByOrder();
    }

    if (!this.hasBoolValue(this.isSortByNameASC)) {
      this.isSortByNameASC = true;
    }

    this.sortByName(this.isSortByNameASC);

    this.isSortByNameASC = !this.isSortByNameASC;
  }
}
class GoogleSheetRow {
  constructor(rowData, order) {
    if (rowData) {
      this.order = order;
      this.name = this.getCellValue(rowData[0]);
      this.term = this.getCellValue(rowData[1]);
      this.type = this.getCellValue(rowData[2]);
      this.outputFormat = this.getCellValue(rowData[3]);
      this.description = this.getCellValue(rowData[4]);
    }
  }

  getCellValue(cell) {
    return cell && cell.formattedValue;
  }
}

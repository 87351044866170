import React from "react";
import axios from "axios";
import SheetsTab from "./tab";
import SheetsContent from "./sheets";
import { getSafe } from "../../../utils/common";

export default class GoogleSpreadSheet extends React.Component {
  componentDidMount() {
    const { spreadsheetUrl, googleSheetsApiKey } = this.props;
    if (!spreadsheetUrl) {
      return;
    }
    let spreadsheetId = this.getSpreadSheetId(spreadsheetUrl);
    if (!spreadsheetId) {
      return "";
    }

    this.setState({
      loading: true
    });

    this.getSheetFromApi(spreadsheetId, googleSheetsApiKey || "AIzaSyDEDmuuw4hugewo9pOArL9LZ_X375vhWMU")
      .then(({data: retVal}) => { 
          let fitlerHiddenSheets = this.filterHiddenSheet(retVal.sheets)
          this.setState({
            spreadsheet: fitlerHiddenSheets
          });
        },
        error => {
          this.setState({ errorMessage: error });
        }
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    let { spreadsheet } = this.state || {};    
    if (this.state && this.state.errorMessage) {
      return getSafe(() => JSON.stringify(this.state.errorMessage));
    }
    return (      
      <div data-duration-in="300" data-duration-out="100" class="w-tabs">
        <SheetsTab sheets={spreadsheet}></SheetsTab>
        <SheetsContent sheets={spreadsheet}></SheetsContent>
      </div>
    );
  }

  getSheetFromApi(inputId, apiKey) {
    return axios.get(
      `https://sheets.googleapis.com/v4/spreadsheets/${inputId}/?key=${apiKey}&includeGridData=true`
    );
  }

  getSpreadSheetId(resourceUrl) {
    return new RegExp("/spreadsheets/d/([a-zA-Z0-9-_]+)").exec(resourceUrl)[1];
  }

  filterHiddenSheet(sheets) {        
    return sheets.filter(sheet => !sheet.properties.hidden);
  }
}

import React from "react";
import { Link } from "gatsby";

export default ({ data: { to, text = "Learn More", addtionalClass = "" } }) => {
  return (
    <Link to={to}>
      <span className="link-text w-inline-block w-clearfix">
        <div className="learn-more">{text}</div>
        <div className="arrow-right" />
      </span>
    </Link>
  );
};

import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import CommonQuestion from "../components/common-question";
import { renderFeatureSection } from "../components/home/featureSection";
import { resetWebflow, getSafe } from "../utils/common";
import { renderTestimonialSection } from "../components/home/testimonialSection";
import ClientAndPlatform from "../components/integration/clientLogoAndPlatform";
import MetricDimension from "../components/integration/metricDimensions";
import Resource from "../components/what-is-data-story-telling/resource";
import Notification from "../components/header/notification";
import Slider from "../components/integration/slider";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsIntegrationDetails2,
    allDatoCmsIntegrationResourceCategory: { edges: resourceCategory },
    allDatoCmsNotification: { edges: notifications },
    datoCmsGlobalConfiguration
  }
}) => {
  if (!datoCmsIntegrationDetails2) {
    return "";
  }

  let { googleSheetsApiKey } =  datoCmsGlobalConfiguration;
  resourceCategory = resourceCategory.map(item => item.node);

  let {
    pageTitle,
    pageDescription,
    integrationType,
    platformImage,
    featuresSection,
    testimonialSection,
    slider,
    clientLogoSection,
    platformCombinationSection,
    commonQuestionsSection,
    metricsDimensionsSection,
    seoMetaTags,
    resources,
    notificationPage
  } = datoCmsIntegrationDetails2;

  let isPremium = integrationType === "Premium";  
  resetWebflow("5a6c0230513ec40001b64711");
  const featureSectionTmpl = renderFeatureSection(featuresSection);
  const testimonialSectionTmpl = renderTestimonialSection(testimonialSection);
  
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <header class="main-header about reducedbottom">
        <div class="container center">
          {platformImage && (
            <div class="platform-logo center">
              <img src={platformImage.url} alt={platformImage.alt} />
            </div>
          )}
          <div class="md-spacing" />
          <h1 class="h-fixedwidth">{pageTitle}</h1>
          <div class="sm-spacing _30" />
          <div class="p center-align">
            <ReactMarkdown source={pageDescription} escapeHtml={false} />
          </div>
          <div class="sm-spacing _30" />
          <Link to="/request-demo" class="btn w-button tracking-demo-header-integration">
            Request Demo
          </Link>
          <div class="_2cols-box _56 center" />
        </div>
      </header>
      <section className="white-section">
        <div className="container">{featureSectionTmpl}</div>
      </section>
      {testimonialSectionTmpl}
      <Slider slider={slider}></Slider>
      <ClientAndPlatform
        isPremium={isPremium}
        client={clientLogoSection}
        platform={platformCombinationSection}
      />
      <section className="white-section gray">
        <div className="container">
          <CommonQuestion data={commonQuestionsSection} />
        </div>
      </section>
      <MetricDimension isPremium={isPremium} data={metricsDimensionsSection} googleSheetsApiKey={googleSheetsApiKey} />
      {isPremium && getSafe(() => resources.length) && (
        <section className="white-section gray">
          <div className="container">
            <Resource category={resourceCategory} detail={resources} />
          </div>
        </section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query IntegrationDetailQuery($slug: String!) {
    datoCmsIntegrationDetails2(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slug
      pageTitle
      integrationType
      platformImage {
        url
        alt
      }
      pageDescription
      featuresSection {
        ... on DatoCmsFeatureSummary {
          id
          title
          description
          cta
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureDetail {
          id
          title
          icon {
            url
            alt
          }
          featurePhoto {
            url
            alt
          }
          model {
            apiKey
          }
        }
      }
      testimonialSection {
        ... on DatoCmsTestimonialDetail {
          text
          userAvatar {            
            url
            alt
          }
          user
          userRole
        }
      }
      slider {
        ... on DatoCmsMainSummary {
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsHeaderDescriptionPhoto {
          header
          description
          photo {
            url
            title
            alt
          }
          model {
            apiKey
          }
        }
      }
      clientLogoSection {
        ... on DatoCmsClientLogoWithHeading {
          sectionTitle
          logos {            
            url
            alt
          }
        }
      }
      platformCombinationSection {
        ... on DatoCmsPlatformCombination {
          id
          title
          description
          image {
            url
            alt
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSummary {
          title
          subTitle
          model {
            apiKey
          }
        }
      }
      commonQuestionsSection {
        ... on DatoCmsQuestion {
          id
          question
          answer
        }
      }
      metricsDimensionsSection {
        ... on DatoCmsMetricsDimensionsList {
          title          
          googleSheetsUrl         
        }
      }
      resources {
        ... on DatoCmsResourceSummary {
          sectionTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsIntegrationResourceDetail {
          id
          thumbnail {
            alt
            url
          }
          title
          source
          cta
          category {
            id
            title
          }
          model {
            apiKey
          }
        }
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsIntegrationResourceCategory {
      edges {
        node {
          id
          title
          icon {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
    }
    datoCmsGlobalConfiguration {            
      googleSheetsApiKey
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;

import React from "react";
import DetailLink from "../../components/detail-link";
import ReactMarkdown from "react-markdown";

export function renderFeatureSection(data) {
  const iconClass = [
    "intergrate-icon",
    "nlg-icon",
    "instant-icon",
    "faster-icon"
  ];
  const imgClass = [
    "combine-data",
    "language-generation",
    "share-live",
    "language-generation"
  ];
  const summary = data.find(
    block => block.model && block.model.apiKey === "feature_summary"
  );
  const details = data.filter(
    block => block.model && block.model.apiKey === "feature_detail"
  );

  return (
    <React.Fragment>
      {summary && (
        <React.Fragment>
          {summary.title && <h2 className="h2 fixedwidth">{summary.title}</h2>}
          {summary.description && (
            <React.Fragment>
              <div className="sm-spacing" />
              <p className="p fixedwidth">
                <ReactMarkdown
                  source={summary.description}
                  escapeHtml={false}
                />
              </p>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      <div className="md-spacing" />
      <div
        data-easing="linear"
        data-duration-in="300"
        data-duration-out="100"
        className="w-tabs"
      >
        <div className="tabs-menu w-tab-menu">
          {details.map((item, idx) => (
            <a
              key={item.icon.url}
              style={{ backgroundImage: `url(${item.icon.url})` }}
              data-w-tab={item.id}
              className={`tabs-link w-inline-block w-tab-link ${
                idx === 0 ? "w--current" : ""
              } ${iconClass[idx % 4]}`}
            >
              <div>{item.title}</div>
            </a>
          ))}
        </div>
        <div className="tabs-content w-tab-content">
          {details.map((item, idx) => (
            <div
              data-w-tab={item.id}
              className={`tabs-pane w-tab-pane ${
                idx === 0 ? "w--tab-active" : ""
              }`}
              key={item.id}
            >
              <img
                src={item.featurePhoto.url}
                alt="combine-data"
                className={imgClass[idx]}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="sm-spacing _30" />
      {summary && <DetailLink data={{ to: summary.cta }} />}
    </React.Fragment>
  );
}

import React from "react";
import { getSafe } from "../../utils/common";
import ReactMarkdown from "react-markdown";
import GoogleSheets from "./google-sheet/spreadsheet";

export default ({ data, googleSheetsApiKey, isPremium }) => {
  let metricDimension = getSafe(() => data[0]) || {};
  let googleSheetUrl = metricDimension.googleSheetsUrl;
  if(!googleSheetUrl){
    return "";
  }
  return (
     <React.Fragment>
      <section class="white-section reducedbottom">
        <div class="container">
          <h2 class="h2 centered">{metricDimension.title}</h2>
          <div class="sm-spacing" />
          <div class="p center-align">
            <ReactMarkdown
              source={metricDimension.description}
              escapeHtml={false}
            />
          </div>
          <div class="container-fluid" />
          <div class="md-spacing" />
          <GoogleSheets spreadsheetUrl={googleSheetUrl} googleSheetsApiKey={googleSheetsApiKey}></GoogleSheets>
        </div>
        {isPremium && <div class="hg-spacing" />}
      </section>
    </React.Fragment>
  );
};
